.calendar {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
    --size: 20rem;
    --font: calc(var(--size) / 20);
    width: var(--size);
    color: #222;
    background-color: #fff;
}

.calendarheading {
    text-align: center;
    font-size: calc(var(--font) * 1.25);
    padding-bottom: var(--font);
    font-weight: bold;
}

.calendargrid {
    box-sizing: border-box;
    display: flex;
    outline: .0625rem solid #222;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.row.header {
    border-bottom: .0625rem solid #222;
}

.cell {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    font-size: var(--font);
    line-height: calc(var(--font) * 2);
    justify-content: space-around;
    text-align: center;
    border: .125rem solid transparent;
}

.cell .todaycircle {
    width: 100%;
    background-color: #222;
    color: #fff;
    border-color: #222;
    border-radius: 100%;
}

.cell.selected {
    border-style: solid;
    border-color: #222;
    background-color: #ededed;
}

.cell.hide {
    visibility: hidden;
}

.row.hide {
    display: none;
}
@import '../variablesmixins.scss';

// todo: componentize the hero completely
.hero {
    width: 100%;
    min-height: 30rem;
    position: relative;
    background-position: 50% 100%;
    background-repeat: no-repeat;
}

.hero h1 {
    position: absolute;
    background-color: rgba(255, 255, 255, .95);
    @include box-shadow-standard;
    left: 0;
    bottom: 4rem;
    padding: 3rem 0.5rem;
    padding-right: 2rem;
    font-size: 3.5rem;
    text-align: center;
}

//change the hero scaling for larger screens
@media (min-width: 1200px) {
    .hero {
        background-size: 100%;
    }
}
@import '../index.scss';
@import '../variablesmixins.scss';

.combobox-container {
    margin-top: 2rem;

    & .combobox-container-inner {
        display: block;
        position: relative;
        max-width: 20rem;
    }

    & label.combobox-label {
        display: inline-block;
        margin-right: rm(8px);
        vertical-align: top;
    }

    & input.combobox {
        display: inline-block;
        width: 16rem;
        height: 2.25rem;
        border-radius: .25rem;
        margin-top: .25rem;
        font-size: 1.125rem;
        border: rm(1px) solid #000;
        padding: .25rem .5rem;
        @include box-shadow-standard;
         &:focus {
             outline-offset: .25rem;
         }
    }

    & ul {
        display: block;
        position: absolute;
        @include box-shadow-standard;
        list-style-type: none;
        border: 1px solid #333;
        background-color: $color-white;
        width: 16rem;
        margin-top: rm(4px);
        padding: .5rem 0rem;
        z-index: 100;
    }

    & li {
        padding: .5rem 1rem;
    }

    & ul:empty {
        display: none;
    }

    & ul.max {
        max-height: 8rem;
        overflow-y: scroll;
    }
}

[role=option] {

    &[aria-selected=true],
    &:hover {
        @include fgBgColors($color-white, $color-dark);
        cursor: pointer;
    }
}

@import '../variablesmixins.scss';

@mixin checkbox-style($color: $color-white, $bgcolor: $color-darkblue) {
    background-color: $bgcolor;
    color: $color;
    display: inline-block;
    position: relative;
    width: 1.125rem;
    height: 1.125rem;
    border: .0625rem solid $color-dark;
    vertical-align: middle;
    @include box-shadow-standard(rgba(0, 0, 0, 0.5));
    padding: 0;
}

.checkbox {
    @include checkbox-style($color-darkblue, $color-white);
}


.checkbox-fake-container {
    @include checkbox-style($color-darkblue, $color-white);

    & .checkbox {
        position: absolute;
        top: -.0625rem;
        left: -.0625rem;
        //unset box-shadow since it's set on the container. Hacky; will probably change later.
        box-shadow: none;
    }
}

.checkbox-container {
    display: inline-block;
    margin-top: .5rem;
    margin-bottom: .5rem;
    position: relative;

    &:hover,
    & .checkbox-fake-container:hover {
        cursor: pointer;
    }

    &:hover .checkbox {
        border-color: $color-purple;
    }
}


.checkbox-hidden {
    position: absolute;
    left: -.0625rem;
    top: -.0625rem;
    opacity: 0;
    margin: 0;
    // account for border in width
    width: calc(100% + .125rem);
    height: calc(100% + .125rem);

    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

.show-focus .checkbox-fake-container input:focus+.checkbox {
    @include focus-state;
}

.checkbox svg {
    padding: .125rem;
    fill: currentColor;
    transition: transform 200ms ease-in-out;
}
.checkbox:not(.checked) svg {
    opacity: 0;
    transform: scale(0);
}


.checkbox.checked svg {
    opacity: 1;
    transform: scale(1);
}

.checked {
    @include fgBgColors($color-white, $color-darkblue);
}

.checkbox-label {
    display: inline-block;
    //use padding instead of margin so that entire label is clickable
    padding-left: .5rem;
    vertical-align: middle;
}

@media(prefers-reduced-motion: reduce) {
    * {
        transition: none !important;
        animation: none !important;
    }
}
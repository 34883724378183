@import '../variablesmixins.scss';

.event {
   & hr {
        height: 0;
        font-size: 0;
        border: 0;
        border-top: 1px solid $color-dark;
    }

    
    & li {
        margin-bottom: 1rem;
    }

    & h5 {
        margin-bottom: .5rem;
    }

    & h5 + ul, & h5 + p {
        margin-top: .5rem;
    }
}


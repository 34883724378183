/* adapted from https://3dtransforms.desandro.com/cube */

.cubeContainer {
    width: 12.5rem;
    height: 12.5rem;
    perspective: 37.5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin: 3rem auto;
}



.opener {
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-content: stretch;
    align-items: stretch;
    padding: 0;
    width: 12.5rem;
    height: 12.5rem;
    z-index: 1000; 
    background: rgba(0,0,0,0);
    border: none;
    color: #fff;
    font-size: 1.5rem;
}

/* transition only. Other cube styles are in JS file */
.opener+.cube {
    opacity: 1;
    visibility: visible;
}

.opener[aria-expanded='false']+.cube {
    opacity: 0;
    visibility: hidden;
}

.opener:focus {
    outline: none;
}

.opener .button-inner {
    flex: 1 1 auto;
    margin: 0;
    padding: calc(50% - .75rem) 0;
    vertical-align: middle;
    background-color: #222;
    transform-origin: center;
    pointer-events: all;
    box-shadow: .125rem .125rem .125rem #222;
    transition: all 200ms ease-in-out;
}

:focus .button-inner {
    text-decoration: underline;
}
:focus .button-inner:first-of-type {
    border-right: none;
}

:focus .button-inner:last-of-type {
    border-left: none;
}

.button-inner:first-of-type {
    text-align: right;
    transform-origin: top center;
}

.opener[aria-expanded='true'] {
    pointer-events: none;
    height: 0;
}

.opener[aria-expanded='true'] .button-inner:first-of-type {
    transform: translateX(-9rem);
}

.opener[aria-expanded='true'] .button-inner:last-of-type {
    transform: translateX(7rem);
}

/* cube styles are in style tag in JS file */

.side {
    list-style-type: none;
    position: absolute;
    display: block;
    width: 12.5rem;
    height: 12.5rem;
    line-height: 12.5rem;
    border: .03125rem solid #222;
}

.side a {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 2.5rem; 
    font-weight: bold;
    color: white;
    text-shadow: .125rem .125rem #222;
    text-align: center;
}

.cube li:focus-within {
    outline: .0625rem solid #222;
    outline-offset: .125rem;
    text-decoration: underline;
}

.big {
    font-size: 3rem;
}

.bolder {
    font-weight: 900;
    color: #c00;
}
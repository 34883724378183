@import './variablesmixins.scss';

* {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}

:root {
    --base-size: 1rem;
    // we can scale fonts against themselves, when nested, by hacking CSS variable scoping!
    --size: var(--base-size);
}

html,
body {
    @include setSize(16px);
    @include fgBgColors($color-dark, $color-light);
    margin: 0;
    padding: 0;
}

.main {
    margin: 1.5rem rm(128px);
    margin-top: 0;
    padding-top: 4rem;
    padding-bottom: 2rem;
    height: 100%;
}

.center-text {
    text-align: center;
}

//used to put a box shadow around things
.module {
    background-color: $color-white;
    width: 100%;
    padding: 1rem;
    box-shadow: 0 0 .25rem .125rem rgba(0, 0, 0, .25);
}

h1,
.h1-like,
h2,
.h2-like,
h3,
.h3-like,
h4,
.h4-like,
h5,
.h5-like,
h6,
.h6-like {
    font-family: 'Lexend Deca', sans-serif;
}

h1,
.h1 {
    @include setSize(48px);
    display: block;
}

a,
[role='link'] {
    color: $color-blue;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

:focus {
    outline: none;
    box-shadow: none;
}

.show-focus :focus {
    @include focus-state($color-dark);
}


.inline-block {
    display: inline-block;
}

.nav-container {
    background-color: $color-dark;
    position: fixed;
    width: 100%;
    z-index: 100;
    @include box-shadow-standard();
}

//make display none on non-mobile breakpoints
.nav-toggle {
    display: none;
    border: none;
    background-color: $color-dark;
    color: $color-white;
    font-size: 2rem;
    height: 3rem;
    width: 3rem;
    padding: 0;
    margin-left: .125rem;

    &:hover {
        cursor: pointer;
    }
}

.show-focus .nav-toggle:focus {
    outline-color: $color-white;
}

.nav-container .nav-ul {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
    max-height: 100rem;
}

.nav-item {
    display: inline-block;
    height: 3rem;
}

.nav-item a {
    font-family: "Lexend Deca", "Arial", sans-serif;
    display: block;
    padding: 1rem;
    color: $color-white;
}

.show-focus .nav-item :focus {
    @include focus-state($color-white);
}

.skip:focus {
    display: block;
    position: absolute;
    color: $color-dark;
    background: $color-white;
    height: 2rem;
    padding: .5rem;
    top: .25rem;
    left: .25rem;
    z-index: 1000;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.sronly:not(:focus) {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px) !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}


@media(min-width: $breakpoint-md + 1px) {
    a.active {
        @include link-active-style($color-purple, $color-white, $color-dark);
        @include box-shadow-standard();
    }
}


@media(max-width: $breakpoint-lg) {
    .main {
        margin-left: 1rem;
        margin-right: 1rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}

@media(max-width: $breakpoint-md) {
    .main {
        margin-left: .25rem;
        margin-right: .25rem;
        padding-left: .75rem;
        padding-top: 3rem;
        padding-right: .75rem;
    }

    h1 {
        font-size: rm(40px);
    }

    .nav-container {
        padding: 0;
    }

    .nav-toggle {
        display: block;

        & svg {
            margin: auto;
            display: block;
        }
    }


    .nav-toggle[aria-expanded='false']+.nav-ul {
        visibility: hidden;
        max-height: 0;
        overflow: hidden;
    }

    .nav-item {
        display: block;
        position: relative;
        padding-left: 0;

        &:hover {
            background-color: $color-white;
        }

        &:hover a {
            color: $color-dark;
        }

        .active::before {
            content: "";
            display: block;
            position: absolute;
            width: .25rem;
            height: 80%;
            left: rm(-2px);
            top: .5rem;
            border-left: .5rem solid $color-white;
        }

    }

}

i,
.noclick {
    pointer-events: none;
}

@media (prefers-reduced-motion: reduce) {
    * {
        transition: all .000001ms !important;
    }
}
$color-dark: #222;
$color-middark: #333;
$color-light: #e8e8e8;
$color-white: #fff;
$color-blue: #2233dd;
$color-purple: #5500dd;
$color-darkblue: #100088;

$breakpoint-sm: 320px;
$breakpoint-md: 420px;
$breakpoint-lg: 960px;
$breakpoint-huge: 1080px;

// http://www.stubbornella.org/content/2013/07/01/easy-peasy-rem-conversion-with-sass/

@function rm($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}

@mixin fgBgColors($color: $color-dark, $bgcolor: $color-white) {
    color: $color;
    background-color: $bgcolor;
}

@mixin setSize($size) {
    font-size: $size; //Fallback in px
    font-size: rm($size);
}

@mixin focus-state($color: $color-dark) {
    outline: rm(2px) solid $color;
}

@mixin box-shadow-standard($color: $color-dark) {
    box-shadow: rm(1px) rm(1px) rm(2px) rm(1px) $color;
}

@mixin basic-shadow($color: $color-dark) {}

@mixin link-active-style($color1: $color-dark, $color2: $color-dark, $color3: $color-white) {
    display: block;
    width: 100%;
    border-bottom: .125rem solid $color1;
    border-left: 0;
    border-right: 0;
    background-color: $color2;
    color: $color3;
    box-shadow: none;
    transform: scale(1.05);
}

@mixin link-active-style-mobile($color: $color-white) {
    display: block;
    height: 100%;
    border-left: rm(4px) solid $color;
}